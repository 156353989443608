#tblRestaurantVolume > div.pre-scrollable {
    max-height: 1000px;
}

div.x-scrollable {
  overflow-x: scroll;
}

.tableFixHead          { overflow-y: auto; max-height: 500px; width: 100%; }
.tableFixHead thead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
.scrollable-table table  { border-collapse: collapse; width: 100%; }
.scrollable-table th, td { padding: 8px 16px; }
.scrollable-table th     { background:#eee; }

