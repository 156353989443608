/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.3
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
// Variables
@import 'app/common/variables';
// Layout
@import 'app/layout/layout';
@import 'app/layout/layout-extra';
@import 'app/layout/layout-animation';
@import 'app/layout/top-navbar';
@import 'app/layout/sidebar';
@import 'app/layout/offsidebar';
@import 'app/layout/user-block';
@import 'app/layout/settings';
// Common
@import 'app/common/page-loader';
@import 'app/common/typo';
@import 'app/common/bootstrap-reset';
@import 'app/common/bootstrap-custom';
@import 'app/common/button-extra';
@import 'app/common/placeholder';
@import 'app/common/cards';
@import 'app/common/circles';
@import 'app/common/dropdown-extra';
@import 'app/common/half-float';
@import 'app/common/animate';
@import 'app/common/slim-scroll';
@import 'app/common/inputs';
@import 'app/common/utils';
@import 'app/common/print';
@import 'app/common/spinner';
@import 'app/common/paginated-table';
@import 'app/common/month-picker';

.status-badge {
  border-radius: 3px;
  padding: 6px 16px;
  opacity: 0.65;
  line-height: 1.5em;
  margin: 1px;
  float: right;
}

.status-badge-active {
  @extend .status-badge;
  border: 1px solid $green;
}

.status-badge-inactive {
  @extend .status-badge;
  border: 1px solid $dark;
  background-color: $secondary;
  color: $dark;
}

.drillable:hover {
  cursor: pointer;
  background-color: $primary;
  opacity: 0.6;
  color: $white;
}

.tooltip-inner {
  background-color: $primary;
}

.arrow::before {
  border-left-color: $primary !important;
}

.modal-title {
  font-size: 1.5rem;
}

.breadcrumb-nav {
  display: inline-block;
}

h2.breadcrumb-nav {
  color: $primary;
  margin-right: 10px;
}

h3.breadcrumb-nav {
  color: $primary;
  margin-left: 10px;
  margin-right: 10px;
}

.dzu-previewImage {
  max-width: 250px !important;
  max-height: 250px !important;
}